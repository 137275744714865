export const emailSettings = {
  serviceId: "",
  appName: "App Lander",
  templateId: "",
  userId: "",
  accessToken: "",
};

/*
  DOCUMENTATION

  The contact form uses EmailJS to send emails. All you need to do is signup to EmailJS at https://www.emailjs.com/
  and in your new account you will find the details to complete the above.

*/
